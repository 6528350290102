import React from 'react';
import PropTypes from 'prop-types';
import './table.scss';

const Table = ({columns, rows, sortColumn, onSort}) => {
  return (
    <table className="table">
      <thead className="table__head">
      <tr>
        {columns.map(column => (
          <th key={column.key} className="table__header-cell">
            {column.label}
            {column.isSortable && (
              <button onClick={() => onSort(column.key)}>{column.key === sortColumn.key ? sortColumn.order : "..."}</button>
            )}
          </th>
        ))}
      </tr>
      </thead>
      <tbody className="table__body">
      {rows.map(item => (
        <tr key={item.id} className="table__row">
          {columns.map(column => (
            <td key={(column.key) + item.id} className="table__data-cell">
              {column.content ? column.content(item) : item[column.key]}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  );
};

const SORT_ORDER = {
  ASC: "asc",
  DESC: "desc"
}

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired, //will be referenced by rows
    label: PropTypes.string,
    isSortable: PropTypes.bool,
    content: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]) //content which will be inserted in every row (like buttons, etc.). Overrides content of row-object
  })),
  rows: PropTypes.arrayOf(PropTypes.object), //Row-content. Consists of objects in shape {<name-of-column-key>: <content (string or jsx)>, <name-of-column-key>: <content>, ...}
  sortColumn: PropTypes.shape({
    key: PropTypes.string, //Name of column-key
    order: PropTypes.string //Value of constant 'SORT_ORDER' (See above)
  }),
  onSort: PropTypes.func //Handler, which receives the column-key as argument
}

export default Table;
export {SORT_ORDER};