import {useState, useCallback, useEffect} from 'react';

let logoutTimer;

export const useAuth = () => {
  const [hasMounted, setHasMounted] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState(null);
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);

  const login = useCallback((userId, role, name, token, expirationDate) => {
    setToken(token);
    setUserId(userId);
    setRole(role);
    setName(name);
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60)
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: userId,
        role: role,
        name: name,
        token: token,
        expiration: tokenExpirationDate.toISOString(),
      }),
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setRole(null);
    setName(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    setHasMounted(true);
  }, [])

  useEffect(() => {
    if (token && tokenExpirationDate) {
      let remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      const dayInMs = 1000 * 60 * 60 * 24;
      if (remainingTime > dayInMs) remainingTime = dayInMs; //setTimout has a max possible value it accepts. Otherwise will fire immediately
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = getStoredData();
    if (storedData) login(storedData.userId, storedData.role, storedData.name, storedData.token, new Date(storedData.expiration));
  }, [login]);

  function getStoredData() {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      return storedData;
    } else {
      return null;
    }
  }
  return {token, login, logout, userId, role, name, hasMounted};
};
