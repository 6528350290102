import React from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import InternDefault from '../components/internDefault';
import ProtectedRoute from '../components/protectedRoute';
import InternLayout from '../components/internLayout';
import InternProfile from '../components/internProfile';
import InternFileGroups from '../components/internFileGroups';
import InternFileList from '../components/internFileList';
import { AuthContext } from '../contexts/authContext';
import { useAuth } from '../hooks/authHook';
import Login from '../components/login';
import SEO from "../components/seo"
// import Profile from "../components/Profile"
// import Details from "../components/Details"
// import Login from "../components/Login"
// import Default from "../components/Default"

const App = () => {
  const {token, login, logout, userId, role, name, hasMounted} = useAuth()

  return (
    <AuthContext.Provider value={{token, login, logout, userId, role, name, hasMounted}}>
      <Layout>
        <SEO title="Älpida Intern"/>
        <Router basepath="/intern">
          <Login path="/login"/>
          <ProtectedRoute path="/profil" layout={InternLayout} component={InternProfile}/>
          <ProtectedRoute path="/kategorien/:fileGroupId" layout={InternLayout} component={InternFileList}/>
          <ProtectedRoute path="/kategorien" layout={InternLayout} component={InternFileGroups}/>
          <ProtectedRoute path="/" layout={InternLayout} component={InternDefault}/>
          {/*<Profile path="/profile" />*/}
          {/*<Details path="/details" />*/}
          {/*<Login path="/login" />*/}
          {/*<Default path="/" />*/}
        </Router>
      </Layout>
    </AuthContext.Provider>
  )
}

export default App
