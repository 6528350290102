import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faCopy, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import Container from './container';
import { AuthContext } from '../contexts/authContext';
import './internLayout.scss';

const InternLayout = ({children}) => {
  const auth = useContext(AuthContext);

  const handleLogout = () => {
    auth.logout();
  }

  return (
    <Container classNames="pt-0">
      <div className="intern-layout">
        <div className="intern-layout__header">
          <span className="intern-layout__heading">Älpida Intern</span>
        </div>
        <div className="intern-layout__sidebar">
          <ul className="intern-layout__sidebar-menu intern-menu">
            <li className="intern-menu__item">
              <Link to="/intern" className="intern-menu__link" activeClassName="intern-menu__link_active">
                <FontAwesomeIcon className="intern-menu__icon" icon={faHome} fixedWidth={true}/>
                <span className="intern-menu__text">Home</span>
              </Link>
            </li>
            <li className="intern-menu__item">
              <Link to="/intern/kategorien" className="intern-menu__link" activeClassName="intern-menu__link_active">
                <FontAwesomeIcon className="intern-menu__icon" icon={faCopy} fixedWidth={true}/>
                <span className="intern-menu__text">Dateien</span>
              </Link>
            </li>
            <li className="intern-menu__item">
              <Link to="/intern/profil" className="intern-menu__link" activeClassName="intern-menu__link_active">
                <FontAwesomeIcon className="intern-menu__icon" icon={faUser} fixedWidth={true}/>
                <span className="intern-menu__text">Profil</span>
              </Link>
            </li>
            <li className="intern-menu__item">
              <button onClick={handleLogout} className="intern-menu__link" activeClassName="intern-menu__link_active">
                <FontAwesomeIcon className="intern-menu__icon" icon={faSignOutAlt} fixedWidth={true}/>
                <span className="intern-menu__text">Logout</span>
              </button>
            </li>
          </ul>
        </div>
        <div className="intern-layout__content">
          {children}
        </div>
      </div>
    </Container>
  );
};

InternLayout.propTypes = {

};

export default InternLayout;
