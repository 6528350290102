//TODO: Feedback Error
import React, { useState, useEffect, useReducer, useContext } from 'react';
import GridRow from './gridRow';
import FileGroupCard from './fileGroupCard';
import { requestReducer } from '../reducers/requestReducer';
import axios from 'axios';
import { AuthContext } from '../contexts/authContext';

const InternFileGroups = () => {
  const auth = useContext(AuthContext);
  const [fileGroups, setFileGroups] = useState([]);
  const [fileGroupRequest, dispatchFileGroupRequest] = useReducer(requestReducer, {
    isLoading: false,
    status: null
  });

  useEffect(() => {
    dispatchFileGroupRequest({type: 'send'});

    const fetchFileGroups = async () => {
      const {data} = await axios({
        method: 'GET',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/aelpida-file-manager/file-groups`,
        headers: {'Authorization': `Bearer ${auth.token}`}
      });
      setFileGroups(data);
    };
    fetchFileGroups();
  }, [])

  return (
    <div>
      <GridRow rowItems={3}>
        {fileGroups.sort((a, b) => a.rank - b.rank).map(fileGroup => (
          <FileGroupCard
            key={fileGroup._id}
            name={fileGroup.name}
            id={fileGroup._id}
          />
        ))}
      </GridRow>
    </div>
  );
};

export default InternFileGroups;
