import React, { useContext, useReducer } from 'react';
import { Link, navigate } from 'gatsby'
import Container from './container';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../hooks/authHook';
import { AuthContext } from '../contexts/authContext';
import './login.scss';

function mailRequestReducer(state, action) {
  switch (action.type) {
    case 'send':
      return {isLoading: true, status: null}
    case 'success':
      return {isLoading: false, status: 'success'}
    case 'error':
      return {isLoading: false, status: 'error'}
  }
}

const Login = () => {
  const auth = useContext(AuthContext);
  const [login, dispatchLogin] = useReducer(mailRequestReducer, {
    isLoading: false,
    status: null
  });

  //TODO: Im Network tab ist passwort im Klartext -> so normal?
  const handleLogin = async (event) => {
    dispatchLogin({type: 'send'});
    event.preventDefault();

    try {
      const loginForm = document.getElementById('loginForm');
      const formData = new FormData(loginForm);

      const {data: {jwt, user} } = await axios({
        method: 'POST',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/auth/local`,
        data: formData
      });
      const {exp} = jwt_decode(jwt);

      auth.login(user.id, user.role.type, user.role.name, jwt, new Date(exp * 1000));
      dispatchLogin({type: 'success'});
      await navigate('/intern');
      loginForm.reset();
    } catch (e) {
      dispatchLogin({type: 'error'});
    }
  }

  return (
    <Container>
      <h1>Login</h1>
      <form className="form" id="loginForm" onSubmit={handleLogin}>
        <div className="form__form-group">
          <label htmlFor="email">E-Mail Adresse</label>
          <input
            type="email"
            id="email"
            name="identifier"
            required={true}
            placeholder="E-Mail Adresse"
          />
        </div>
        <div className="form__form-group">
          <label htmlFor="password">Passwort</label>
          <input
            type="password"
            id="password"
            name="password"
            required={true}
            placeholder="Passwort"
          />
        </div>
        <div className="form__form-group">
          <button className="button button_size_large-1" type="submit">
            {login.isLoading ? (
              <FontAwesomeIcon className="" icon={faSpinner} spin/>
            ) : (
              "Login"
            )}
          </button>
        </div>
        <span className={`form__status-text ${login.status === 'error' && 'form__status-text_error'}`}>
          {login.status === 'error' && "Login fehlgeschlagen"}
        </span>
      </form>
      <div className="login__forgot-password">
        <Link to="/passwort-vergessen" >
          Passwort vergessen
        </Link>
      </div>
    </Container>
  );
};

export default Login;
