import React, { useContext } from 'react';
import { Link } from 'gatsby';

const InternProfile = () => {

  return (
    <React.Fragment>
      <p>
        Um das Passwort zu ändern, bitte auf untenstehenden Link klicken. Du erhältst anschließend eine E-Mail mit einem Link zur Passwortänderung.
      </p>
      <Link to="/intern/passwort-zuruecksetzen">Passwort ändern</Link>
    </React.Fragment>
  );
};

export default InternProfile;
