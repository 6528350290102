//TODO: Protect
import React, { useContext } from 'react';
import PropTypes from "prop-types"
import { navigate } from 'gatsby'
import jwt_decode from 'jwt-decode';
import { AuthContext } from '../contexts/authContext';
import Container from './container';

const ProtectedRoute = ({component: Component, layout: Layout, location, ...rest}) => {
  const auth = useContext(AuthContext);
  let exp;

  if (!auth.hasMounted) {
    return (
      <Container>
        <div style={{textAlign: 'center'}}>
          Seite lädt. Bitte warten ...
        </div>
      </Container>
    )
  }

  if (auth.token) {
    const currentTime = Math.ceil(Date.now() / 1000);
    const decodedToken = jwt_decode(auth.token);
    exp = decodedToken.exp;
    if (exp && exp > currentTime) {
      return (
        <Layout >
          <Component location={location} {...rest}/>
        </Layout>
      );
    }
  }

  navigate('/intern/login');
  return null;
};

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired, //TODO: any -> correct?
}

export default ProtectedRoute;