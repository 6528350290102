import React, { useContext } from 'react';
import { AuthContext } from '../contexts/authContext';
import { Link } from 'gatsby';

const InternDefault = () => {
  const auth = useContext(AuthContext);

  return (
    <p>
      Hallo,<br />
      in deinem Konto-Dashboard kannst du auf die <Link to="/intern/kategorien">Dateien</Link> zugreifen und dein <Link to="/intern/profil">Passwort ändern</Link>.
    </p>
  );
};

export default InternDefault;
