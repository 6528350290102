import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import './fileGroupCard.scss';

const FileGroupCard = ({name, id}) => {
  return (
      <Link className="mini-card" to={`/intern/kategorien/${id}`}>
        <span className="mini-card__text">{name}</span>
      </Link>
  );
};

FileGroupCard.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default FileGroupCard;