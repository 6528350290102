//TODO: Feedback Error
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Table from './table';
import DownloadIcon from '../images/download.png';
import { AuthContext } from '../contexts/authContext';
import { requestReducer } from '../reducers/requestReducer';
import axios from 'axios';

const InternFileList = (props) => {
  const fileGroupId = props.fileGroupId;
  console.log(fileGroupId)
  const auth = useContext(AuthContext);
  const [fileList, setFileList] = useState([]);
  const [fileGroupRequest, dispatchFileGroupRequest] = useReducer(requestReducer, {isLoading: false, status: null});

  useEffect(() => {
    dispatchFileGroupRequest({type: 'send'});

    const fetchFileList = async () => {
      const {data} = await axios({
        method: 'GET',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/aelpida-file-manager/file-groups/${fileGroupId}/files`,
        headers: {'Authorization': `Bearer ${auth.token}`}
      });
      setFileList(data);
    };
    fetchFileList();
  }, []);

  const handleDownloadFile = async (id) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.GATSBY_BACKEND_SERVER_URL}/aelpida-file-manager/files/${id}`,
        headers : {
          Authorization: `Bearer ${auth.token}`,
        },
        responseType: 'arraybuffer'
      });

      let blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileList.find(file => file.id === id).filename;
      link.click();

    } catch (e) {
      console.log(e);
    }
  }

  const columns = [
    {
      key: 'name',
      label: 'Datei'
    },
    {
      key: 'updatedAt',
      label: 'Datum',
      content: file => (
        <span>{(new Date(file.updatedAt)).toLocaleDateString()}</span>
      )
    },
    {
      key: 'actions',
      content: file => (
        <div className="afm-homepage__table-action-buttons">
          <button className="icon-button" type="button" onClick={()=> handleDownloadFile(file._id)} label="Aktualisieren"><img src={DownloadIcon} /></button>
        </div>
      )
    }
  ];

  return (
    <div>
      <Table columns={columns} rows={fileList}/>
    </div>
  );
};

export default InternFileList;
